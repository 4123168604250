<template>
  <div class="mission-vision" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Mission"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000">
      {{ $t("mission-vision-title") }}
    </h1>

    <div class="section">

      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('carbo-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('carbo-paragraph')"
      ></div>

      <div class="cover" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[1]" alt="CarboBrake Mission"/>
      </div>

      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('mission-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('mission-paragraph')"
      ></div>

      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('vision-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('vision-paragraph')"
      ></div>

      <div class="cover" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[2]" alt="CarboBrake Mission"/>
      </div>

      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
          v-html="$t('values-title')"
      ></div>
      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
          v-html="$t('values-paragraph')"
      ></div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/mission_vision_1.png'),
        require('../assets/mission_vision_2.png'),
        require('../assets/mission_vision_3.png'),
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"></style>